.parent {
  padding-left: 0.5px;
}

.parent:hover .step {
  @apply text-gray-700;
}

.parent:hover .description {
  @apply text-gray-1000;
}

.control {
  filter: brightness(0) saturate(100%) invert(16%) sepia(47%) saturate(6772%) hue-rotate(350deg)
    brightness(86%) contrast(105%);
  @apply w-full;
}

.laststep {
  @apply w-full;
}

.step {
  margin-bottom: 0.3rem;
}
