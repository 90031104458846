.grid {
  display: grid;
  grid-template-columns: 0.32fr 1fr 3fr 2fr 1fr;
  border-top: 2px solid #e7e7e7;
  background-color: #ffffff;
}

.grid {
  padding: 16px 24px;
}

.shoeImage {
  object-fit: cover;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .grid {
    display: grid;
    grid-template-columns: unset;
    border-top: 0.5px solid #e7e7e7;
    background-color: #ffffff;
  }
  .relDateGrid {
    justify-content: left !important;
    grid-column-start: 1;
    grid-row-start: 1;
    grid-row-end: 1;
  }
  .shoeImageGrid {
    grid-column-start: 2;
    grid-row-start: 1;
    grid-row-end: 1;
    justify-self: end;
  }
  .shoeTextGrid {
    margin-left: 0px;
    margin-right: 0px;
    padding-bottom: 24px;
    grid-row-start: 2;
    grid-column-start: 1;
    grid-column-end: 3;
    border-bottom: 1px solid #eeeeee;
  }
  .guideAndRaffleGrid {
    grid-column-start: 1;
    grid-row-start: 3;
    border-left: unset !important;
  }
  .detailsButtonGrid {
    grid-column-start: 2;
    grid-row-start: 3;
  }
}

.relDateGrid {
  display: flex;
  align-items: center;
  justify-content: center;
}

.guideAndRaffleGrid {
  margin-top: 24px;
  margin-bottom: 24px;
  border-left: 1px solid #eeeeee;
}

.grid:last-of-type {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.upcoming .grid:first-of-type {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-top: none;
}

.grid:hover > div button {
  color: #c50e11;
  cursor: pointer;
  border-color: #c50e11;
}

.upcoming a:hover {
  color: #c50e11;
  cursor: pointer;
}

.mtGrid {
  display: grid;
  padding-top: 65px;
  padding-bottom: 65px;
  border-top: 0.5px solid lightgrey;
  background-color: #ffffff;
}

.grayedOut {
  color: #bdbdbd;
}

.mtGrid:last-of-type {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.center {
  display: flex;
  justify-content: left;
  margin-left: 16px;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .center {
    margin-left: 0px;
    justify-content: start;
  }
}

.centerShoeName {
  display: flex;
  align-items: center;
}

.rafflesGrey {
  background: #eeeeee;
  border-radius: 100px;
  padding: 2px 10px;
  color: #bdbdbd;
  font-size: 14px;
}

.rafflesGreen {
  color: #064e3b;
  background: #d1fae5;
  border-radius: 100px;
  padding: 2px 10px;
  font-size: 14px;
}

.releaseGuideGrey {
  background: #eeeeee;
  border-radius: 100px;
  padding: 2px 10px;
  color: #bdbdbd;
  font-size: 14px;
}

.releaseGuideGreen {
  color: #064e3b;
  background: #d1fae5;
  border-radius: 100px;
  padding: 2px 10px;
  font-size: 14px;
}
