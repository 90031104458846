.banner {
  flex-shrink: 0;
  @apply w-3/6 lg:w-full bg-no-repeat bg-cover bg-center rounded-r-xl lg:rounded-t-xl;
}

.banner span {
  margin-top: 2px;
}

.content {
  height: auto;
  background-color: white;
  flex-grow: 1;
  @apply flex flex-col h-full rounded-l-xl lg:rounded-b-xl p-4;
}

.description {
  @apply font-labRegular h-full text-xs leading-4 max-h-12 lg:text-sm pb-6 lg:leading-5 lg:max-h-10 text-gray-700 overflow-hidden ;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

@media (min-width: 1024px) {
  .banner {
    height: 120px;
    border-bottom-right-radius: 0px;
  }

  .content {
    border-top-left-radius: 0px;
  }

  .description {
    -webkit-line-clamp: 2;
  }
}

.textFix {
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
}
