.quickImage {
  background-repeat: no-repeat;
  background-position: right 0px top 0px;
  background-size: 300px 300px;
}

@media (max-width: 640px) {
  .quickImage {
    background-size: 200px 200px;
  }

  .quickImage h4 {
    @apply text-xl;
  }
}

@media (min-width: 640px) {
  .quickImage {
    background-size: 275px 275px;
  }
}

@media (min-width: 768px) {
  .quickImage {
    background-size: 125px 125px;
  }
}

@media (min-width: 1024px) {
  .quickImage {
    background-size: 175px 175px;
  }
}

@media (min-width: 1280px) {
  .quickImage {
    background-size: 200px 200px;
  }
}

@media (min-width: 1536px) {
  .quickImage {
    background-size: 300px 300px;
  }
}
