.container {
  @apply grid grid-cols-1 w-full pt-0 lg:grid-cols-3 lg:pt-4 pb-12 lg:pb-32;
}

.container div {
  @apply flex flex-col justify-center;
}

.container div div {
  max-width: 306px;
  @apply flex;
}

/* .container div:first-of-type {
  @apply pt-12 pb-6 lg:pt-10;
} */

.container h1 {
  color: #151515;
  @apply pb-3 text-2xl font-tommyBold font-bold;
}

.container p {
  max-width: 305px;
  color: #616161;
  @apply pb-3 text-base;
}

.container a span {
  color: #c50e11;
}

.container a:hover {
  color: #c50e11;
  cursor: pointer;
}

.border {
  border-color: #e0e0e0;
  @apply border-t border-b lg:border-l lg:border-r lg:border-t-0 lg:border-b-0 lg:items-center;
}
